html {
  box-sizing: border-box;
  --color-grey: #212529;
  --color-darkgrey: #141617;
  --color-blue: #222831;
  --color-orange: #ff5722;
  --color-white: #eeeeee;
  --color-offwhite: #aaaaaa;
  --color-lime: #b5e853;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Cairo', 'Roboto', Arial, Helvetica, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-darkgrey);
  color: var(--color-white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 20px 50px;
  width: 100%;
}

.normal-ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.mono {
  font-family: 'Red Hat Mono', monospace;
  font-weight: 400;
}

.mono.mono-500 {
  font-weight: 500;
}

.mono.mono-600 {
  font-weight: 600;
}

.logo {
  font-weight: bold;
  height: 40px;
}

.nav-links {
  list-style: none;
  display: flex;
  height: 40px;
}

.nav-item {
  cursor: pointer;
}

.nav-item a {
  display: inline-block;
  text-decoration: none;
  padding: 10px 15px;
  color: var(--color-offwhite);
}

.nav-item:hover {
  border-bottom: 4px solid var(--color-lime);
}

.nav-item:hover a {
  color: var(--color-white);
}

.text-justify {
  text-align: justify;
}

.text-left {
  text-align: left;
}

.grecaptcha-badge {
    visibility: hidden;
    display: none;
}
